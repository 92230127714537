<template>
  <InfoBlock>
    <template v-slot:title>Дайджест СМИ</template>

    <template v-if="items && items.length === 0">
      Данных не найдено
    </template>

    <ArticlesGrid v-if="items && items.length > 0" :items="items" :getKey="(item) => item.id">
      <template v-slot="item">
        <InfoArticle
          :url="getArticleLink(item.id)"
          :title="item.name"
          :description="item.previewText"
          :date="item.activeFrom"
        />
      </template>
    </ArticlesGrid>
  </InfoBlock>
</template>

<script>
  import ArticlesGrid from '@/common/components/ArticlesGrid.vue';
  import InfoArticle from '@/common/components/InfoArticle.vue';
  import InfoBlock from '@/common/components/InfoBlock.vue';
  import Utils from '@/common/utils.js';

  import API from '../../api/widgetElement.js';

  export default {
    name: 'NewsPortalInfoBlock',

    components: {
      InfoBlock,
      ArticlesGrid,
      InfoArticle
    },

    props: {
      countryId: {
        type: [String, null],
        required: false
      }
    },

    data() {
      return {
        items: null
      }
    },

    methods: {
      async fetchData() {
        this.items = await API.getNews({ countryId: this.countryId })
          .then((response) => response?.data)
      },

      getArticleLink(id) {
        return Utils.getPublicBaseUrl() + 'news/item/' + id;
      }
    },

    created() {
      this.fetchData();
    },

    watch: {
      countryId() {
        this.fetchData();
      }
    }
  }
</script>

<style lang="scss" scoped></style>
