<template>
  <ul class="articles-grid">
    <li class="articles-grid__item" v-for="(item, index) in items" :key="getKey(item, index)">
      <slot v-bind="item"></slot>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'ArticlesGrid',

    props: {
      items: {
        type: Array,
        required: true
      },
      getKey: {
        type: Function,
        required: false,
        default: (item, index) => index
      }
    }
  }
</script>

<style lang="scss" scoped>
  .articles-grid {
    --_articles-grid-breakpoint: 210px;
    --_articles-grid-breakpoint-gap: 32px;
    margin: 0;
    padding: 0;
    list-style-type: '';
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, var(--_articles-grid-breakpoint)), 1fr));
    gap: var(--_articles-grid-breakpoint-gap);
  }
</style>
