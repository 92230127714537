<template>
  <form class="country-form" @submit.prevent="onSubmit">
    <Field>
      <template v-slot:label>{{ label }}</template>
      <div class="country-form__filter">
        <Multiselect
          class="country-form__control"
          v-model="countryId"
          :options="countryList"
          valueProp="id"
          label="text"
          searchable
          placeholder="Выберите"
        ></Multiselect>
        <Button mod="gradient-bg" class="country-form__button">Показать</Button>
        </div>
    </Field>
  </form>
</template>

<script>
import Multiselect from '@vueform/multiselect';

import Field from '@/common/components/Field.vue';
import Button from '@/common/components/redesigned/ButtonComponent.vue';

export default {
  name: 'CountryForm',

  components: {
    Multiselect,
    Field,
    Button
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    countryList: {
      type: [Array, null]
    },
    route: [Function, Object],
  },

  data() {
    return {
      countryId: null
    }
  },

  computed: {
    computedRoute() {
      return typeof this.route === 'function'
        ? this.route(this.countryId)
        : this.route;
    }
  },

  methods: {
    onSubmit() {
      if (this.countryId) {
        this.$router.push(this.computedRoute);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .country-form {
    width: 308px;
    max-width: 100%;
  }

  .country-form__filter {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .country-form__control {
    flex: 9999 1 200px;
  }

  .country-form__button {
    flex: 1 1 auto;
  }
</style>
