<template>
  <InfoBlock gutter="medium">
    <template v-slot:title>Календарь мероприятий</template>

    <div class="events-info-block">
      <div class="events-info-block__calendar">
        <Calendar
          columns="1"
          step="1"
          :attributes="calendarAttributes"
          @edit-card="viewEvent"
          mode="single"
          @did-move="onCalendarChange"
        />
      </div>
      <div class="events-info-block__main">
        <Field class="events-info-block__filter">
          <template v-slot:label>Тип мероприятия</template>
          <form class="events-info-block__form" @submit.prevent="fetchEvents">
            <Multiselect
              v-model="eventTypeId"
              :options="eventTypesList"
              valueProp="id"
              label="name"
              placeholder="Выберите"
            />
            <ButtonComponent mod="gradient-bg">Показать</ButtonComponent>
            <RouterLink custom v-slot="{ href, navigate }" :to="{name: 'Events', query: countryId ? { countryId } : null }">
              <ButtonComponent type="link" :href="href" @click="navigate">Все мероприятия</ButtonComponent>
            </RouterLink>
          </form>
        </Field>

        <div class="events-info-block__table" v-if="events !== null">
          <DataTable v-if="events.length > 0" :items="events" :itemKey="(item) => item.id">
            <DataTableColumn id="tools" widthValue="0">
              <template v-slot:body="{ item }">
                <Toolbar
                  v-if="canEdit"
                  class="table-tools"
                  @edit="editEvent(item.id)"
                  @delete="deleteEvent(item.id)"
                />
              </template>
            </DataTableColumn>

            <DataTableColumn id="name" label="Название">
              <template v-slot:body="{ item }">
                <p>{{ item.name }}</p>
                <SimpleLink component="RouterLink" :to="getViewEventRoute(item.id)">
                  Подробнее
                </SimpleLink>
              </template>
            </DataTableColumn>

            <DataTableColumn id="date" label="Дата" widthValue="0">
              <template v-slot:body="{ item }">
                <time v-if="item.eventLastDate && item.eventDatesList?.length > 1" class="events-info-block__date">{{ formatDate(item.eventLastDate) }}</time>
                <time v-if="item.eventFirstDate" class="events-info-block__date">{{ formatDate(item.eventFirstDate) }}</time>
              </template>
            </DataTableColumn>
          </DataTable>
          <p v-else>Событий не найдено</p>
        </div>
      </div>
    </div>

  </InfoBlock>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import moment from 'moment';

import { DataTable, DataTableColumn } from '@/common/components/DataTable';
import Field from '@/common/components/Field.vue';
import InfoBlock from '@/common/components/InfoBlock.vue';
import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
import Calendar from '@/common/components/redesigned/Calendar.vue';
import SimpleLink from '@/common/components/SimpleLink';
import Toolbar from '@/common/components/Toolbar';
import Constants from '@/common/constants';
import security from '@/common/mixins/security';

import EventsAPI from '@/modules/mpk/api/event.js';
import NsiAPI from '@/modules/nsi/api/nsi.js';

import WidgetAPI from '../../api/widgetElement.js';

export default {
  name: 'EventsInfoBlock',

  components: {
    Multiselect,
    InfoBlock,
    Calendar,
    Field,
    ButtonComponent,
    DataTable,
    DataTableColumn,
    Toolbar,
    SimpleLink
  },

  mixins: [security],

  props: {
    countryId: {
      type: [String, null],
      default: null
    }
  },

  data() {
    return {
      eventTypeId: null,
      eventTypesList: [],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      events: null,
      eventsCount: 5,
      canEdit: false,
    }
  },

  computed: {
    eventListForCalendar() {
      return (this.events ?? []).reduce((acc, event) => {
        if (event.eventDatesList) {
          event.eventDatesList.forEach((date) => {
            const eventToPush = Object.assign({}, event, {
              eventDatesList: [date],
              time: moment(date).format('HH:mm')
            });
            acc.push(eventToPush);
          });
        }
        return acc;
      }, []);
    },

    calendarAttributes() {
      return this.eventListForCalendar.map((event) => ({
        dates: event.eventDatesList.map(d => new Date(d)),
        dot: {
          color: event.color,
          class: event.statusId == 3 ? 'opacity-75' : '',
        },
        popover: {
          label: event.name,
          visibility: 'click',
          isInteractive: true,
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
        customData: event,
      }));
    },
  },

  async mounted() {
    [
      this.eventTypesList,
      this.events,
      this.canEdit
    ] = await Promise.all([
      this.getEventsTypes(),
      this.getEvents(),
      this.checkPermission(Constants.Permissions.MpkEventsEdit).then(response => response.data)
    ]);
  },

  watch: {
    async eventTypeId(newValue) {
      if (newValue === null) {
        await this.fetchEvents();
      }
    },

    countryId() {
      this.fetchEvents();
    }
  },

  methods: {
    async getEventsTypes() {
      const response = await NsiAPI.search('mpkeventtype');
      return response?.data?.items;
    },

    async getEvents() {
      const response = await WidgetAPI.getEvents({
        year: this.year,
        month: this.month,
        countryId: this.countryId,
        eventTypeId: this.eventTypeId,
      });
      return response?.data?.slice?.(0, this.eventsCount);
    },

    async fetchEvents() {
      this.events = await this.getEvents();
    },

    editEvent(eventId) {
      this.$router.push({
        name: 'EventCard',
        params: {
          id: eventId,
          action: 'edit'
        },
      })
    },

    viewEvent(eventId) {
      this.$router.push({
        name: 'EventCard',
        params: {
          id: eventId,
          action: 'view'
        },
      })
    },

    getViewEventRoute(eventId) {
      return {
        name: 'EventCard',
        params: {
          id: eventId,
          action: 'view'
        },
      }
    },

    async deleteEvent(eventId) {
      const confirmation = await Constants.alert.fire(Constants.confirmOptions.delete);

      if (!confirmation.isConfirmed) {
        return;
      }

      try {
        await EventsAPI.delete(eventId);
        Constants.alert.fire('Удаление', 'Запись удалена', 'success');
        this.fetchEvents();
      } catch (error) {
        Constants.alert.fire(
          'Удаление',
          error.response && error.response.data ? error.response.data : 'Запись не удалена',
          'error',
        );
      }
    },

    onCalendarChange(columnList) {
      const [column] = columnList;
      const { year, month } = column;
      this.year = year;
      this.month = month;
      this.fetchEvents();
    },

    formatDate(date) {
      return (new Date(date)).toLocaleDateString('ru-Ru');
    },
  }
}
</script>

<style lang="scss" scoped>
  .events-info-block {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    column-gap: 72px;

    @media (min-width: $desktop) {
      flex-direction: row;
      align-items: start;

      .events-info-block__calendar {
        flex: 0 0 auto;
      }

      .events-info-block__main {
        flex: 1 1 0%;
      }
    }
  }

  .events-info-block__filter {
    margin-bottom: 16px;
  }

  .events-info-block__form {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 8px;

    @media (min-width: $laptop-2) {
      flex-direction: row;

      & > * {
        flex: 0 0 auto;
      }

      & > :first-child {
        margin: 0;
        flex: 1 1 auto;
        max-width: 270px;
      }
    }

    @media (min-width: $desktop) {
      & > :first-child {
        max-width: none;
      }
    }
  }

  .events-info-block__table {
    overflow: auto;
    max-width: 100%;
    min-width: 0;
  }

  .events-info-block__date {
    opacity: 0.7;
    display: block;
    white-space: nowrap;
  }

  .table-tools {
    margin-left: -20px;
  }

  .simple-link {
    font: $fira-12;
    color: $blue-2;

    &:hover {
      color: $blue;
    }

    &:visited {
      color: $purple;
    }
  }
</style>
