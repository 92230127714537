<template>
  <InfoBlock class="projects-info-block" gutter="small">
    <template v-slot:title>Перечень проектов</template>

    <template v-if="canAccess === true && projects?.length > 0">
      <div class="projects-info-block__table">
        <DataTable
          :items="projects"
          :itemKey="(item) => item.id"
          @rowDoubleClick="onRowDoubleClick"
        >
          <DataTableColumn id="tools" widthValue="0">
            <template v-slot:body="{ item }">
              <Toolbar
                v-if="canEditProjects && item.canEdit"
                @edit="editItem(item.id)"
                @delete="deleteItem(item.id)"
                class="table-tools"
              />
            </template>
          </DataTableColumn>
          <DataTableColumn id="status" label="Статус" widthValue="0">
            <template v-slot:body="{ item }">
              <ProjectStatus :id="item.statusId">
                {{ item.status.name }}
              </ProjectStatus>
            </template>
          </DataTableColumn>
          <DataTableColumn id="сountry" label="Страна" widthValue="0">
            <template v-slot:body="{ item }">
              {{ item.country.name }}
            </template>
          </DataTableColumn>
          <DataTableColumn id="name" label="Название" field="name" widthValue="50%"></DataTableColumn>
          <DataTableColumn id="start" label="Начало" widthValue="130px">
            <template v-slot:body="{ item }">
              <time class="projects-info-block__date">
                {{ formatDate(item.startMonthYear) }}
              </time>
            </template>
          </DataTableColumn>
          <DataTableColumn id="end" label="Конец" widthValue="130px">
            <template v-slot:body="{ item }">
              <time class="projects-info-block__date">
                {{ formatDate(item.endMonthYear) }}
              </time>
            </template>
          </DataTableColumn>
        </DataTable>
      </div>

      <RouterLink custom v-slot="{ href, navigate }" :to="{ name: 'Projects', query: { countryId }}">
        <ButtonComponent type="link" :href="href" @click="navigate">Все проекты</ButtonComponent>
      </RouterLink>
    </template>

    <template v-else>
      <p>Проектов не найдено</p>
    </template>

    <p v-if="canAccess === false">
      У Вас нет доступа к просмотру матрицы проектов
    </p>
  </InfoBlock>
</template>

<script>
  import { DataTable, DataTableColumn } from '@/common/components/DataTable';
  import InfoBlock from '@/common/components/InfoBlock.vue';
  import ProjectStatus from '@/common/components/ProjectStatus.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Toolbar from '@/common/components/Toolbar.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ProjectsAPI from '@/modules/mpkProject/api/index.js';

  export default {
    name: 'ProjectsInfoBlock',

    components: {
      InfoBlock,
      DataTable,
      DataTableColumn,
      ButtonComponent,
      Toolbar,
      ProjectStatus
    },

    mixins: [security],

    props: {
      countryId: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        canAccess: null,
        canEditProjects: null,
        projects: null
      }
    },

    methods: {
      editItem(id) {
        this.$router.push({
          name: 'EditProjectCard',
          params: { id }
        });
      },

      viewItem(item) {
        if (item.canView) {
          this.$router.push({
            name: 'ProjectCard',
            params: { id: item.id }
          })
        } else {
          Constants.alert.fire('Недостаточно прав', 'У вас недостаточно прав для просмотра данных проекта', 'error');
        }
      },

      onRowDoubleClick(event, item) {
        this.viewItem(item);
      },

      async deleteItem(id) {
        const confirmation = await Constants.alert.fire(Constants.confirmOptions.delete);
        if (!confirmation.isConfirmed) {
          return;
        }

        try {
          await ProjectsAPI.delete(id);
          Constants.alert.fire('Удаление проекта', Constants.commonSuccess, 'success');
          await this.fetchProjects();
        } catch (error) {
          Constants.alert.fire('Удаление проекта', Constants.commonError, 'error');
        }
      },

      async fetchProjects() {
        this.projects = await ProjectsAPI.search({
          countryIds: [this.countryId],
          pageNumber: 1,
          pageSize: 3
        }).then((response) => response?.data.items);
      },

      formatDate(date) {
        return date ? this.$momentFormat(date, 'MM.YYYY') : '';
      }
    },

    async mounted() {
      [
        this.canAccess,
        this.canEditProjects
      ] = await Promise.all([
        this.checkPermission(Constants.Permissions.MpkProjectsAccess).then((response) => response.data),
        this.checkPermission(Constants.Permissions.MpkProjectEdit).then((response) => response.data)
      ]);

      await this.fetchProjects();
    },

    watch: {
      countryId(newValue) {
        if (newValue !== null) {
          this.fetchProjects();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .projects-info-block {}

  .projects-info-block__table {
    margin-bottom: 16px;
    overflow: auto;
  }

  .projects-info-block__date {
    white-space: nowrap;
  }

  .table-tools {
    margin-left: -20px;
  }
</style>
