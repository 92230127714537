<template>
  <span class="project-status" :class="getClassStatus(id)">
    <slot></slot>
  </span>
</template>

<script>
  export default {
    name: 'ProjectStatus',

    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    methods: {
      getClassStatus(id) {
        switch (id) {
          case 1:
            return 'green-dark';
          case 2:
            return 'green';
          case 3:
            return 'blue';
          default:
            return 'warning';
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/global/variables.scss';

  .project-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    color: $white;

    &.green-dark {
      background-color: $approval-text;
    }

    &.green {
      background-color: $green-2;
    }

    &.blue {
      background-color: $blue;
    }

    &.warning {
      background-color: $red-2;
    }
  }
</style>
