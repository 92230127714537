<template>
  <div class="container-page foreign-office-page">
    <div>
      <header class="foreign-office-page__header">
        <h2 class="foreign-office-page__title">Кабинет сотрудника ЗАГРАНАППАРАТА</h2>

        <div class="foreign-office-page__side-tools">
          <RouterLink :to="{ name: 'Library' }" v-slot="{ href, navigate }" custom>
            <ButtonComponent type="link" mod="blue-color" :href="href" @click="navigate">
              Библиотека документов
            </ButtonComponent>
          </RouterLink>

          <RouterLink :to="{ name: 'MassMediaCard', params: { id: 0, action: 'edit'}}" v-slot="{ href, navigate }" custom>
            <ButtonComponent type="link" mod="blue-color" :href="href" @click="navigate">
              Создать дайджест
            </ButtonComponent>
          </RouterLink>
        </div>
      </header>

      <InlineMultiSelect
        :rootAttrs="{ class: 'foreign-office-page__country-select' }"
        v-model="countryId"
        :options="countryList"
        valueProp="id"
        label="text"
        searchable
        :canClear="countryList && countryList.length > 1"
        noResultsText="Стран не найдено"
      >
        <template v-slot:label>Страна</template>
      </InlineMultiSelect>

      <div class="foreign-office-page__filters" v-if="!isCountrySelected">
        <CountryForm
          label="Аналитика по стране"
          :countryList="countryList"
          :route="(countryId) => ({ name: 'countryAnalytics', query: countryId ? { countryId } : null })"
        />
        <CountryForm
          label="Профили МПК"
          :countryList="countryList"
          :route="(countryId) => ({ name: 'Commissions', query: countryId ? { countryId } : null })"
        />
      </div>
    </div>

    <!-- Календарь мероприятий -->
    <EventsInfoBlock :countryId="countryId" />

    <!-- Поручения сотруднику Загранаппарата -->
    <AssignmentInfoBlock :countryId="countryId" />

    <!-- Профиль МПК -->
    <MpkProfileInfoBlock :countryId="countryId" v-if="countryId" />

    <!-- Новостной портал ВАВТ -->
    <MassMedialInfoBlock :countryId="countryId" />

    <!-- Дайджест СМИ -->
    <NewsPortalInfoBlock :countryId="countryId" />

    <!-- Перечень проектов -->
    <ProjectsInfoBlock :countryId="countryId" v-if="countryId" />

    <!-- Горячие показатели -->
    <CountryAnalyticsInfoBlock :countryId="countryId" v-if="countryId" />
  </div>
</template>

<script>
import CountryForm from '@/common/components/CountryForm.vue';
import InlineMultiSelect from '@/common/components/InlineMultiSelect.vue';
import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
import security from '@/common/mixins/security';
import Utils from '@/common/utils';

import AssignmentInfoBlock from '../components/foreignOffice/AssignmentInfoBlock.vue';
import CountryAnalyticsInfoBlock from '../components/foreignOffice/CountryAnalyticsInfoBlock.vue';
import EventsInfoBlock from '../components/foreignOffice/EventsInfoBlock.vue';
import MassMedialInfoBlock from '../components/foreignOffice/MassMedialInfoBlock.vue';
import MpkProfileInfoBlock from '../components/foreignOffice/MpkProfileInfoBlock.vue';
import NewsPortalInfoBlock from '../components/foreignOffice/NewsPortalInfoBlock.vue';
import ProjectsInfoBlock from '../components/foreignOffice/ProjectsInfoBlock.vue';

export default {
  name: 'ForeignOffice',

  components: {
    ButtonComponent,
    InlineMultiSelect,
    CountryForm,
    EventsInfoBlock,
    AssignmentInfoBlock,
    MpkProfileInfoBlock,
    NewsPortalInfoBlock,
    MassMedialInfoBlock,
    ProjectsInfoBlock,
    CountryAnalyticsInfoBlock
  },

  mixins: [security],

  data() {
    return {
      countryId: this?.$route.query?.countryId ?? null,
      countryList: null,
    }
  },

  computed: {
    isCountrySelected() {
      return this.countryId !== null && this.countryId !== undefined;
    }
  },

  methods: {
    async fetchCountries() {
      this.countryList = await Utils.searchNsi('countryList', {})
        .then((response) => response.data);
    }
  },

  async created() {
    await this.fetchCountries();
  },

  watch: {
    countryId() {
      this.$router.replace({
        query: this.countryId ? { countryId: this.countryId } : null
      });
    },

    countryList(list) {
      if (list.length === 1) {
        this.countryId = list[0]?.id;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .foreign-office-page {
    --_foreign-office-page-gutter: 56px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: var(--_foreign-office-page-gutter);
    padding-bottom: var(--_foreign-office-page-gutter);
  }

  .foreign-office-page__header {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: start;
    justify-content: space-between;

    @media (max-width: $laptop) {
      margin-bottom: 32px;
    }
  }

  .foreign-office-page__title {
    flex: 1 1 310px;
    font: bold 20px/28px "Fira Sans";
    text-transform: uppercase;
    color: $base;
  }

  .foreign-office-page__side-tools {
    flex: 0 1 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    & > * {
      flex: 1 1 auto;
    }
  }

  .foreign-office-page__country-select {
    max-width: 450px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .foreign-office-page__filters {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 24px;
  }
</style>
