<template>
  <article class="info-article">
    <component class="info-article__title info-article__truncate" :is="titleComponent" :title="title">
      <template v-if="url">
        <a class="info-article__link" :href="url" target="_blank" rel="noopener noreferrer">
          {{ title }}
        </a>
      </template>
      <template v-else>
        {{ title }}
      </template>
    </component>
    <time class="info-article__date" :datetime="isoDate" v-if="date">{{ formattedDate }}</time>
    <p class="info-article__description info-article__truncate" v-if="description">
      {{ description }}
    </p>
  </article>
</template>

<script>
  export default {
    name: 'InfoArticle',

    props: {
      titleComponent: {
        type: [String, Object],
        required: false,
        default: 'h3'
      },
      title: {
        type: String,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      date: {
        type: [String, Date],
        required: false
      },
      description: {
        type: String,
        required: false
      }
    },

    computed: {
      isoDate() {
        return (new Date(this.date)).toISOString();
      },

      formattedDate() {
        return (new Date(this.date)).toLocaleDateString('ru-Ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).replace('г.', '');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/global/variables.scss';

  .info-article {
    font: $fira-12;
  }

  .info-article__truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .info-article__title {
    font: $fira_14-M;
  }

  .info-article__link {
    // color: $blue;
    font: inherit;
  }

  .info-article__date {
    margin-top: 4px;
    display: block;
  }

  .info-article__description {
    margin-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>
