<template>
  <InfoBlock gutter="medium">
    <template v-slot:title>Профиль МПК</template>

    <ul v-if="commissionsData?.items?.length > 0" class="tree-list">
      <li v-for="item in commissionsData.items" :key="item.id" class="tree-list__item">
        <template v-if="commissionsData.map[item.id]?.length > 0">
          <details class="tree-list__collapse">
            <summary class="tree-list__header">
              <RouterLink :to="{name: 'CommissionCard', params: { action: 'view', id: item.id, type: 'mp'}}" class="tree-list__link">
                <IconComponent name="external-link" class="tree-list__link-icon" />
                <span>{{ item.name }}</span>
              </RouterLink>
              <IconComponent name="arrowCurrentColor" class="tree-list__state-icon" />
            </summary>
            <ul v-if="commissionsData.map[item.id]?.length > 0" class="tree-list">
              <li v-for="item in commissionsData.map[item.id]" :key="item.id" class="tree-list__item">
                <RouterLink :to="{name: 'CommissionCard', params: { action: 'view', id: item.id, type: 'rg'}}" class="tree-list__link">
                  <IconComponent name="external-link" class="tree-list__link-icon " />
                  <span>{{ item.name }}</span>
                </RouterLink>
              </li>
            </ul>
          </details>
        </template>
        <template v-else>
          <RouterLink :to="{name: 'CommissionCard', params: { action: 'view', id: item.id, type: 'mp'}}" class="tree-list__link">
              <IconComponent name="external-link" class="tree-list__link-icon" />
              <span>{{ item.name }}</span>
            </RouterLink>
        </template>
      </li>
    </ul>

    <template v-if="commissionsData && commissionsData.items?.length === 0">
      <p>Данных не найдено</p>
    </template>
  </InfoBlock>
</template>

<script>
  import InfoBlock from '@/common/components/InfoBlock.vue';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import CommissionsAPI from '@/modules/mpk/api/commission.js'

  export default {
    name: 'MpkProfileInfoBlock',

    mixins: [security],

    components: {
      InfoBlock,
      IconComponent
    },

    props: {
      countryId: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        commissionsData: null
      }
    },

    methods: {
      async fetchCommissions() {
        const items = await CommissionsAPI.search({
          countryId: this.countryId
        }).then(response => response?.data.items);

        this.commissionsData = items.reduce((acc, item) => {
          if (!item.parentId) {
            acc.items.push(item);
          } else {
            acc.map[item.parentId] = acc.map[item.parentId] ?? [];
            acc.map[item.parentId].push(item);
          }
          return acc;
        }, {
          items: [],
          map: {}
        })

      }
    },

    watch: {
      countryId() {
        this.fetchCommissions();
      }
    },

    created() {
      this.fetchCommissions();
    }
  }
</script>

<style lang="scss" scoped>
  .tree-list {
    margin: 0;
    padding: 0;
    list-style-type: '';
    display: flex;
    flex-direction: column;
    gap: 16px;
    font: 15px/20px 'Fira Sans';

    & & {
      margin-left: 20px;
      margin-top: 16px;
    }
  }

  .tree-list__link {
    display: inline-flex;
    vertical-align: top;
    gap: 4px;
    align-items: start;
    color: $blue-2;

    &:hover {
      color: $blue;
    }
  }

  .tree-list__link-icon {
    margin-top: 2px;
  }

  .tree-list__state-icon {
    margin-top: 5px;
    position: relative;
    flex: 0 0 auto;
    color: $blue-2;
    transition: transform 0.2s;

    &::before {
      content: '';
      position: absolute;
      inset: -10px;
    }
  }

  .tree-list__header {
    display: inline-flex;
    vertical-align: top;
    align-items: start;
    gap: 8px;

    &::-webkit-details-marker {
      display:none;
    }

    &::marker {
      display: none;
    }
  }

  .tree-list__header:focus > .tree-list__state-icon,
  .tree-list__state-icon:hover {
    color: $blue;
  }

  .tree-list__collapse[open] .tree-list__state-icon {
    transform: rotate(0.5turn);
  }
</style>
