<template>
  <InfoBlock class="assignment-info-block" gutter="xx-small">
    <template v-slot:title>Поручения сотруднику Загранаппарата</template>

    <div class="assignment-info-block__table">
      <p v-if="assignments && assignments.length === 0" class="mt-16">
        Поручений не найдено
      </p>

      <DataTable
        v-if="assignments?.length > 0"
        :items="assignments"
        :item-key="(item) => item.id"
        @rowDoubleClick="onRowDoubleClick"
      >
        <DataTableColumn v-if="canEdit" id="tools" widthValue="0" :bodyStyle="toolCellStyles" :headStyle="toolCellStyles">
          <template v-slot:body="{ item }">
            <Toolbar @edit="startEditAssignment(item)" />
          </template>
        </DataTableColumn>
        <DataTableColumn id="assignment" label="Поручение" field="assignmentName" />
        <DataTableColumn id="date-start" label="Дата поступления" widthValue="0">
          <template v-slot:body="{ item }">
            <time class="assignment-info-block__date">
              {{ formatDate(item.created) }}
            </time>
          </template>
        </DataTableColumn>
        <DataTableColumn id="correspondent" label="Корреспондент">
          <template v-slot:body="{ item }">
            <EmployeeList v-if="item.correspondent" :items="[item.correspondent]" />
          </template>
        </DataTableColumn>
        <DataTableColumn id="deadline" label="Срок" widthValue="0">
          <template v-slot:body="{ item }">
            <time class="assignment-info-block__date">
              {{ formatDate(item.deadline) }}
            </time>
          </template>
        </DataTableColumn>
        <DataTableColumn id="responsible" label="Ответственный">
          <template v-slot:body="{ item }">
            <EmployeeList :items="item.responsibleEmployees" />
            <SimpleLink component="button" type="button" @click="showResponsibleModal(item)" v-if="canEdit">
              изменить
            </SimpleLink>
          </template>
        </DataTableColumn>
        <DataTableColumn id="status" label="Статус" widthValue="160px">
          <template v-slot:body="{ item }">
            <div class="text-center">
              <AssignmentStatus :assignment="item" />
            </div>
          </template>
        </DataTableColumn>
        <DataTableColumn id="report" label="Отчет">
          <template v-slot:body="{ item }">
            <a
              v-if="item.report && item.reportDocument"
              :href="getReportFileUrl(item.reportDocument.id)"
              target="_blank" rel="noopener noreferrer"
            >
              {{ item.report }}
            </a>
        </template>
        </DataTableColumn>
      </DataTable>
    </div>

    <div class="assignment-info-block__assignments-tools">
      <ButtonComponent mod="gradient-bg" @click="startEditAssignment" v-if="canEdit">
        Новое поручение
      </ButtonComponent>

      <RouterLink custom v-slot="{ href, navigate }" :to="{ name: 'EapAssignments', query: countryId ? { countryId } : null }">
        <ButtonComponent type="link" :href="href" @click="navigate">
          Все поручения
        </ButtonComponent>
      </RouterLink>
    </div>

    <!-- Модальное окно смены ответственного -->
    <ModalComponent
      v-if="responsibleModalModel"
      v-model="responsibleModalModel"
      title="Сменить ответственного"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeResponsibleModal"
    >
      <div class="responsibles-form">
        <div class="responsibles-form__control">
          <SelectMultiComponentCheckbox
            titleOptions="Ответственный"
            v-model="responsiblesModel"
            :options="displayedAvailableResponsibles"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :max="3"
          />
        </div>

        <div class="responsibles-form__footer">
          <ButtonComponent mod="gradient-bg" @click="saveResponsibles">Применить</ButtonComponent>
          <ButtonComponent @click="closeResponsibleModal">Отменить</ButtonComponent>
        </div>
      </div>
    </ModalComponent>

    <!-- Модальное окно создания/редактирования поручения -->
    <ModalComponent
      v-if="fullAssignmentModalModel"
      v-model="fullAssignmentModalModel"
      title="Форма создания поручения"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeAssignmentModal"
    >
        <EapAssignmentCardAdd
          :id="editableAssignment?.id ?? null"
          v-on:save="afterSaveAssignment"
          v-on:close="closeAssignmentModal"
          :countryId="countryId"
          :projectId="null"
        />
    </ModalComponent>

    <ModalInfo
      v-model="isOpenModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :textModalPreWrap="textModalPreWrap"
      :modalIconName="modalIconName"
      @closeModal="closeModalInfo"
    />
  </InfoBlock>
</template>

<script>
  import { toRaw } from 'vue';
  import { cloneDeep } from 'lodash';

  import { DataTable, DataTableColumn } from '@/common/components/DataTable'
  import InfoBlock from '@/common/components/InfoBlock.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SimpleLink from '@/common/components/SimpleLink';
  import Toolbar from '@/common/components/Toolbar';
  import Constants from '@/common/constants';
  import CommonConstants from '@/common/constants.js';
  import modalInfo from '@/common/mixins/modalInfo';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils.js';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import AssignmentAPI from '@/modules/eap/api/assignment.js';
  import AssignmentStatus from '@/modules/eap/components/AssignmentStatus.vue';
  import EapAssignmentCardAdd from '@/modules/eap/components/EapAssignmentCardAdd.vue';
  import EmployeeList from '@/modules/eap/components/EmployeeList.vue';
  import { SortColumn } from '@/modules/eap/utils/constants.js';

  export default {
    name: 'AssignmentInfoBlock',

    components: {
      InfoBlock,
      DataTable,
      DataTableColumn,
      ButtonComponent,
      Toolbar,
      AssignmentStatus,
      ModalComponent,
      ModalInfo,
      SelectMultiComponentCheckbox,
      SimpleLink,
      EmployeeList,
      EapAssignmentCardAdd
    },

    mixins: [security, modalInfo],

    props: {
      countryId: {
        type: String,
        required: false
      }
    },

    data() {
      return {
        canEdit: false,
        // макс. количество строк для отображения в таблице
        itemsCount: 3,
        assignments: [],

        editableAssignment: null,

        isResponsibleModalOpen: false,
        availableResponsibles: null,

        isFullAssignmentModalOpen: false,
      }
    },

    computed: {
      // используются инлайн-стили, чтобы не задавать глобальный (не scoped) класс
      toolCellStyles() {
        return [{
          paddingLeft: '0px',
          paddingRight: '12px',
        }]
      },

      displayedAvailableResponsibles() {
        return this.availableResponsibles?.map?.((employee) => ({
          id: employee.id,
          text: employee.fullName
        }))
      },

      responsibleModalModel: {
        get() {
          return this.isResponsibleModalOpen && !!this.editableAssignment;
        },
        set(value) {
          if (value === false) {
            this.editableAssignment = null;
            this.isResponsibleModalOpen = false;
          }
        }
      },

      fullAssignmentModalModel: {
        get() {
          return this.isFullAssignmentModalOpen && !!this.editableAssignment;
        },
        set(value) {
          if (value === false) {
            this.editableAssignment = null;
            this.isFullAssignmentModalOpen = false;
          }
        }
      },

      responsiblesModel: {
        get() {
          return this.editableAssignment?.responsibleEmployees?.map?.((employee) => employee.id);
        },
        set(ids) {
          Object.assign(this.editableAssignment, {
            responsibleEmployees: ids.map((id) => this.responsiblesMap[id])
          })
        }
      },

      responsiblesMap() {
        return (this.availableResponsibles ?? []).reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});
      }
    },

    methods: {
      async fetchAssignments() {
        this.assignments = await AssignmentAPI.search({
          pageNumber: 1,
          pageSize: this.itemsCount,
          countryId: this.countryId,
          sortColumn: SortColumn.ReceiptDate,
          sortOrder: CommonConstants.sortOrder.descending
        }).then(response => response?.data?.items);
      },

      async fetchResponsibles() {
        this.availableResponsibles = await AssignmentAPI.getResponsibleCandidates({
          countryId: this.countryId
        }).then(response => response?.data);
      },

      formatDate(date) {
        return this.$momentFormat(date, 'DD MMMM YYYY');
      },

      getReportFileUrl(id) {
        return Utils.getFileUrl(id);
      },

      viewAssignment(id) {
        this.$router.push({
          name: 'EapAssignmentCard',
          params: {
            id,
            action: 'view'
          }
        })
      },

      showResponsibleModal(item) {
        this.isResponsibleModalOpen = true;
        this.editableAssignment = cloneDeep(toRaw(item));
      },

      closeResponsibleModal() {
        this.isResponsibleModalOpen = false;
      },

      startEditAssignment(item = {}) {
        this.editableAssignment = cloneDeep(toRaw(item));
        this.isFullAssignmentModalOpen = true;
      },

      closeAssignmentModal() {
        this.fullAssignmentModalModel = false;
      },

      async saveResponsibles() {
        if (!this.editableAssignment) {
          return;
        }
        try {
          await AssignmentAPI.update(this.editableAssignment);
          this.isOpenModalInfo = true;
          this.titleModal = 'Сохранение';
          this.textModal = 'Данные успешно применены';
          this.modalIconName = 'acceptIcon';
          await this.fetchAssignments();
        } catch (error) {
          this.isOpenModalInfo = true;
          this.titleModal = 'Сохранение';
          this.textModal = Constants.commonError
          this.modalIconName = 'errorIcon';
        } finally {
          this.closeResponsibleModal();
        }
      },

      async afterSaveAssignment() {
        this.closeAssignmentModal();
        await this.fetchAssignments();
      },

      onRowDoubleClick(event, item) {
        this.viewAssignment(item.id);
      },
    },

    async created() {
      this.canEdit = await this.checkPermission(Constants.Permissions.EapAssignmentEdit)
        .then((response) => response?.data)
        .catch(() => false);

      this.fetchAssignments();
      this.fetchResponsibles();
    },

    watch: {
      countryId() {
        this.fetchAssignments();
        this.fetchResponsibles();
      },
    }
  }
</script>

<style lang="scss" scoped>
  .assignment-info-block {}

  .assignment-info-block__table {
    overflow: auto;
  }

  .assignment-info-block__assignments-tools {
    margin-top: 24px;
    display: inline-flex;
    vertical-align: top;
    width: fit-content;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 16px;

    > * {
      flex: 1 1 auto;
    }
  }

  .assignment-info-block__date {
    white-space: nowrap;
  }

  .responsibles-form {}

  .responsibles-form__control {
    min-height: 222px;
  }

  .responsibles-form__footer {
    margin-top: 32px;
    display: flex;
    gap: 16px;
  }

</style>
