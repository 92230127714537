<template>
  <InfoBlock headerSpread>
    <template v-slot:title>Новостной портал ВАВТ</template>

    <template v-slot:tools>
      <CustomLink :to="{ name: 'News', query: { tab: 3 } }">
        Все новости
      </CustomLink>
    </template>

    <template v-if="items && items.length === 0">
      Данных не найдено
    </template>

    <ArticlesGrid v-if="items?.length > 0" :items="items" :getKey="(item) => item.id">
      <template v-slot="item">
        <InfoArticle
          :url="item.url"
          :title="item.brief"
          :description="item.description"
          :date="item.datePublish"
        />
      </template>
    </ArticlesGrid>
  </InfoBlock>
</template>

<script>
  import ArticlesGrid from '@/common/components/ArticlesGrid.vue';
  import CustomLink from '@/common/components/CustomLink.vue';
  import InfoArticle from '@/common/components/InfoArticle.vue';
  import InfoBlock from '@/common/components/InfoBlock.vue';

  import API from '../../api/widgetElement.js';

  export default {
    name: 'MassMedialInfoBlock',

    components: {
      CustomLink,
      InfoBlock,
      InfoArticle,
      ArticlesGrid
    },

    props: {
      countryId: {
        type: [String, null],
        required: false
      },
    },

    data() {
      return {
        items: null,
        displayCount: 5
      }
    },

    methods: {
      async fetchData() {
        this.items = await API.getMassMedia({ countryId: this.countryId })
          .then((response) => response?.data)
          .then((list) => list?.slice?.(0, this.displayCount))
      }
    },

    created() {
      this.fetchData();
    },

    watch: {
      countryId() {
        this.fetchData();
      }
    }
  }
</script>

<style lang="scss" scoped></style>
