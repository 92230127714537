<template>
  <section class="info-block" :class="rootClasses">
    <header class="info-block__header" :class="headerClasses">
      <h2 v-if="$slots.title" class="info-block__title">
        <slot name="title"></slot>
      </h2>
      <template v-if="$slots.tools">
        <slot name="tools"></slot>
      </template>
    </header>
    <div class="info-block__body">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    gutter: {
      type: String,
      default: 'large',
      validator(value) {
        return [
          'none',
          'xx-small',
          'x-small',
          'small',
          'medium',
          'large'
        ].includes(value);
      },
    },

    noBorder: {
      type: Boolean,
      default: false
    },

    headerSpread: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    rootClasses() {
      return [
        `info-block--gutter_${this.gutter}`
      ];
    },

    headerClasses() {
      return {
        'info-block__header--noBorder': this.noBorder,
        'info-block__header--spread': this.headerSpread
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-block {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 24px;
  }

  .info-block--gutter_none {
    grid-row-gap: 0;
  }

  .info-block--gutter_xx-small {
    grid-row-gap: 8px;
  }

  .info-block--gutter_x-small {
    grid-row-gap: 16px;
  }

  .info-block--gutter_small {
    grid-row-gap: 24px;
  }

  .info-block--gutter_medium {
    grid-row-gap: 32px;
  }

  .info-block--gutter_large {
    grid-row-gap: 40px;
  }

  .info-block__header {
    padding-bottom: 8px;
    border-bottom: 1px solid $grey;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .info-block__header--noBorder {
    border: none;
  }

  .info-block__header--spread {
    justify-content: space-between;
  }

  .info-block__title {
    font: $fira-20-M;
  }

  .info-block__body {}
</style>
