<template>
  <table class="data-table">
    <colgroup>
      <col
        v-for="column in columns"
        :key="column.props.id"
        :style="{ width: column.props.widthValue }"
      />
    </colgroup>

    <thead class="data-table__head">
      <tr class="data-table__row">
        <th
          v-for="column in columns"
          :key="column.props.id"
          class="data-table__cell data-table__cell--head"
          :class="column.props.headClass"
          :style="column.props.headStyle"
        >
          <template v-if="hasColumnSlot(column, 'head')">
            <component :is="getColumnSlot(column, 'head')" :column="column.props" />
          </template>
          <template v-else>
            {{ column.props.label }}
          </template>
        </th>
      </tr>
    </thead>

    <tbody class="data-table__body">
      <tr
        v-for="(item, index) in items"
        :key="itemKey(item, index)"
        class="data-table__row"
        @click="onRowClick($event, item)"
        @dblclick="onRowDoubleClick($event, item)"
      >
        <td
          v-for="column in columns"
          :key="column.props.id"
          class="data-table__cell data-table__cell--body"
          :class="column.props.bodyClass"
          :style="column.props.bodyStyle"
        >
          <template v-if="hasColumnSlot(column, 'body')">
            <component :is="getColumnSlot(column, 'body')" :column="column.props" :item="item" />
          </template>
          <template v-else>
            {{ item[column.props.field] }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { DataTableColumnName } from './constants.js';

  export default {
    name: 'DataTable',

    emits: ['rowClick', 'rowDoubleClick'],

    props: {
      items: {
        type: Array,
        default: () => [],
      },

      // функция вычисления `key` для циклов `v-for`
      itemKey: {
        type: Function,
        default: (item, index) => index,
      },
    },

    computed: {
      columns() {
        return this.$slots.default()
          .filter((vnode) => vnode.type.name === DataTableColumnName);
      },
    },

    methods: {
      hasColumnSlot(columnNode, slotName) {
        return !!this.getColumnSlot(columnNode, slotName);
      },

      getColumnSlot(columnNode, slotName) {
        return columnNode?.children?.[slotName];
      },

      onRowClick(event, item) {
        this.$emit('rowClick', event, item);
      },

      onRowDoubleClick(event, item) {
        this.$emit('rowDoubleClick', event, item);
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import './styles.scss';
</style>
