<template>
  <div class="inline-multiselect" v-bind="rootAttrs">
    <label class="inline-multiselect__label" v-if="$slots.label" @click="onLabelClick">
      <slot name="label"></slot>
    </label>
    <template v-if="hasSingleOption">
      <span class="inline-multiselect__single-option">
        {{  singleOptionsLabel }}
      </span>
    </template>
    <template v-else>
      <Multiselect
        class="inline-multiselect__control"
        :class="{'inline-multiselect__control--shrink': !hasValue }"
        v-bind="selectPropsAndAttrs"
        ref="multiselect"
      />
    </template>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    Multiselect
  },
  props: {
    ...Multiselect.props,
    // атрибуты, которые нужно добавить на корневой элемент
    rootAttrs: [Object],
  },
  computed: {
    // пробрасываем все пропсы, кроме `rootAttrs`, на компонент `Multiselect`
    selectPropsAndAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { rootAttrs, ...restProps } = this.$props;
      return {
        ...this.$attrs,
        ...restProps
      };
     },

     hasValue() {
      const { modelValue, value } = this.selectPropsAndAttrs;
      return !!(modelValue || value);
     },

     hasSingleOption() {
      return !!(this.options?.length === 1);
     },

     singleOptionsLabel() {
      return this.options[0]?.[this.label];
     }
  },

  methods: {
    onLabelClick() {
      this.$refs?.multiselect?.open();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/theme/default/global/variables';

.inline-multiselect {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font: 500 24px/28px "Fira Sans";
}

.inline-multiselect__label {
  margin: 0;
  flex: 0 0 auto;
  cursor: pointer;
}

.inline-multiselect__single-option {
  flex: 1 1 auto;
  color: $blue;
}

.inline-multiselect__control {
  margin: 0;
  flex: 1 1 220px;

  &--shrink:not(&.is-open) {
    justify-content: start;

    :deep(.multiselect-wrapper) {
      margin: 0;
      max-width: 24px;
    }
  }

  &.multiselect {
    border: none;
    font: inherit;
    background: none;
    min-height: 0;
  }

  :deep(.multiselect-wrapper) {
    min-height: 100%;
  }

  :deep(.multiselect-single-label) {
    padding-left: 0;
    padding-right: calc(24px * 2 + 4px * 2);
    color: $blue;
  }

  :deep(.multiselect-search) {
    padding-left: 0;
    font: inherit;
    border: none;
    background: none;
  }

  :deep(.multiselect-caret) {
    margin: 0;
    transform: none;
    width: 24px;
    height: 24px;
    border: none;
    mask-size: 24px 24px;
    mask-position: center;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' stroke-linecap='square' stroke-width='2' d='m6 9 6 6 6-6'/%3E%3C/svg%3E");
    background-color: $blue;

    &.is-open {
      transform: rotate(0.5turn);
    }
  }

  :deep(.multiselect-clear) {
    margin-inline: 4px;
  }

  :deep(.multiselect-clear-icon) {
    width: 24px;
    height: 24px;
    mask-size: 24px 24px;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='M17 7 7 17M7 7l10 10'/%3E%3C/svg%3E");
  }
}
</style>
